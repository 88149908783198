<script lang="ts" setup>
import type { FragmentType } from '@/gql';
import { graphql } from '@/gql';
import { useWindowSize } from '@vueuse/core';
import AppMarkdown from 'theme/components/atoms/AppMarkdown.vue';
import ButtonFlat from 'theme/components/atoms/ButtonFlat.vue';
import InView from 'theme/components/atoms/InView.vue';

const AgreementViewTermRevisionFragmentDef = graphql(`
  fragment AgreementViewTermRevision on TermRevision {
    id
    title
    body
  }
`);
const terms = useComputedFragment(
  AgreementViewTermRevisionFragmentDef,
  () => props.terms
);

const props = withDefaults(
  defineProps<{
    terms: FragmentType<typeof AgreementViewTermRevisionFragmentDef>[];
    disagreeable?: boolean;
  }>(),
  {
    disagreeable: false,
  }
);
const emit = defineEmits<{
  agree: [ids: string[]];
  disagree: [];
}>();
const isScrolledToTheBottom = ref(false);
const onScrolledToTheBottom = () => {
  isScrolledToTheBottom.value = true;
};

const { height } = useWindowSize();
const modalHeight = computed(() => {
  const h = height.value;
  return h ? `${h * 0.8}px` : '80vh';
});
const titles = computed(() =>
  terms.value.map(({ title }) => `「${title}」`).join('と')
);

const onSubmit = () => {
  emit(
    'agree',
    terms.value.map(({ id }) => id)
  );
};

const disagree = () => {
  emit('disagree');
};
</script>

<template>
  <div :class="$style.agreement">
    <h3 :class="$style.agreement__header">{{ titles }}への同意のお願い</h3>
    <div v-test="'agreementContent'" :class="$style.agreement__contents">
      <AppMarkdown v-for="term in terms" :key="term.id" :body="term.body" />
      <InView :class="$style.inview" @enter="onScrolledToTheBottom" />
    </div>
    <footer :class="[$style.agreement__footer, $style.footer]">
      <p v-if="!isScrolledToTheBottom" :class="$style.agreement__note">
        最後までご確認ください
      </p>
      <ButtonFlat
        :class="$style.agreement__button"
        variant="primary"
        :disabled="!isScrolledToTheBottom"
        @click="onSubmit"
      >
        <slot name="submit" />
      </ButtonFlat>
      <button
        v-if="disagreeable"
        :class="$style['footer__disagree-button']"
        @click="disagree"
      >
        同意しない
      </button>
    </footer>
  </div>
</template>

<style lang="scss" module>
.agreement {
  display: flex;
  flex-direction: column;
  max-width: 420px;
  width: 100%;
  /* height: 80dvh; */
  height: v-bind(modalHeight);

  &__header {
    flex: 0 0 auto;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  &__contents {
    flex: 1 0 0px;
    border-radius: $border-radius-rounder;
    padding: 16px;
    max-width: 400px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    &:nth-child(n + 2) {
      margin-top: 8px;
    }
  }

  &__footer {
    flex: 0 0 auto;
    text-align: center;

    &:nth-child(n + 2) {
      margin-top: 16px;
    }
  }

  &__note {
    color: $color-caution-accent;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
  }

  &__checkbox {
    margin-left: 0px;

    &:nth-child(n + 2) {
      margin-top: 16px;
    }
  }

  &__button {
    width: 100%;

    &:nth-child(n + 2) {
      margin-top: 16px;
    }
  }
}

.inview {
  width: 100%;
  height: 1px;
}

.footer {
  &__disagree-button {
    &:nth-child(n + 2) {
      margin-top: 12px;
    }
  }
}
</style>
