<script lang="ts" setup>
import TermView from './TermView.vue';
import type { FragmentType } from '@/gql';
import { graphql } from '@/gql';
import ButtonFlat from 'theme/components/atoms/ButtonFlat.vue';
import CheckboxBlock, {
  type CheckboxBlockOption,
} from 'theme/components/molecules/CheckboxBlock.vue';

const AgreementViewWithCheckboxTermRevisionFragmentDef = graphql(`
  fragment AgreementViewWithCheckboxTermRevision on TermRevision {
    id
    title
    ...TermViewTermRevision
  }
`);
const terms = useComputedFragment(
  AgreementViewWithCheckboxTermRevisionFragmentDef,
  () => props.terms
);

const props = defineProps<{
  terms: FragmentType<
    typeof AgreementViewWithCheckboxTermRevisionFragmentDef
  >[];
}>();

const emit = defineEmits<{
  agree: [ids: string[]];
}>();

const termIdBeingDisplayed = ref<string | null>(null);
const termBeingDisplayed = computed(() =>
  terms.value.find((t) => t.id === termIdBeingDisplayed.value)
);

const backButtonClicked = () => (termIdBeingDisplayed.value = null);

const style = useCssModule();
const termLinkComponent = (id: string, title: string) =>
  h(
    'a',
    {
      onClick: () => {
        termIdBeingDisplayed.value = id;
      },
      class: style['annotation__link'],
    },
    `${title}`
  );

const titles = computed(() => {
  const inner = terms.value
    .flatMap(({ id, title }) => [termLinkComponent(id, title), 'と'])
    .slice(0, -1);
  return h('span', {}, inner);
});

const onSubmit = () =>
  emit(
    'agree',
    terms.value.map(({ id }) => id)
  );

const selectedTermIds = ref<string[]>([]);

const AgreementViewTermRevisionFragment = useComputedFragment(
  AgreementViewWithCheckboxTermRevisionFragmentDef,
  () => props.terms
);

const options = computed((): CheckboxBlockOption<string, string>[] =>
  AgreementViewTermRevisionFragment.value.map((t) => {
    return {
      value: t.id,
      content: t.title,
    };
  })
);

const submitDisabled = computed(() =>
  AgreementViewTermRevisionFragment.value.some(
    (t) => !selectedTermIds.value.includes(t.id)
  )
);
</script>

<template>
  <div>
    <TermView
      v-if="termBeingDisplayed"
      :term="termBeingDisplayed"
      @click-back-button="backButtonClicked"
    />
    <div v-else :class="$style.agreement">
      <h3 :class="$style.agreement__header">同意事項</h3>
      <div
        v-test="'agreementWithCheckboxContent'"
        :class="$style.agreement__contents"
      >
        <p :class="$style.contents__annotation">
          ※<component
            :is="titles"
          />をお読みいただき、同意の上で開始してください。
        </p>
        <div :class="[$style.contents__checkboxes, $style.checkboxes]">
          <CheckboxBlock
            v-for="option in options"
            :key="option.value"
            v-model="selectedTermIds"
            :class="$style.checkboxes__checkbox"
            :option="option"
          >
            {{ option.content }}に同意する
          </CheckboxBlock>
        </div>
      </div>
      <footer :class="[$style.agreement__footer, $style.footer]">
        <ButtonFlat
          :class="$style.agreement__button"
          variant="primary"
          :disabled="submitDisabled"
          @click="onSubmit"
        >
          同意してサービスを利用する
        </ButtonFlat>
      </footer>
    </div>
  </div>
</template>

<style lang="scss" module>
.agreement {
  display: flex;
  flex-direction: column;
  margin: 8px;
  max-width: 420px;

  &__header {
    flex: 0 0 auto;
    font-size: 20px;
    line-height: 36px;
    font-weight: bold;
    text-align: center;
  }

  &__contents {
    flex: 1 0 0px;
    border-radius: $border-radius-rounder;
    margin-top: 16px;
    max-width: 400px;
    height: 100%;
  }

  &__footer {
    flex: 0 0 auto;
    text-align: center;

    &:nth-child(n + 2) {
      margin-top: 16px;
    }
  }

  &__button {
    width: 100%;
  }
}

.contents {
  &__annotation {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 14px;
    line-height: 25.2px;
  }
}

.annotation {
  &__link {
    text-decoration: underline;
    cursor: pointer;
  }
}

.checkboxes {
  margin-top: 16px;

  &__checkbox {
    &:nth-child(n + 2) {
      margin-top: 8px;
    }
  }
}
</style>
