<script setup lang="ts">
import IconCloseCircle from '~theme/assets/icons/outline/close-circle.svg?component';
import IconMessageCircle from '~theme/assets/icons/outline/message-circle.svg?component';

type Variant = 'positive' | 'neutral' | 'warning';

type Props = {
  variant?: Variant;
  message: string;
  closable?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  variant: 'neutral',
  closable: false,
});

const emits = defineEmits<{
  close: [];
}>();

const style = useCssModule();
const classes = computed(() => [
  style.container,
  style[`container--${props.variant}`],
]);
</script>

<template>
  <div :class="classes">
    <IconMessageCircle :class="$style.container__icon" />
    <p :class="$style.container__body">{{ message }}</p>
    <IconCloseCircle
      v-if="closable"
      :class="$style.container__icon"
      @click="emits('close')"
    />
  </div>
</template>

<style module lang="scss">
.container {
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: $shadow-4;

  &__body {
    flex: 1;
  }

  &__icon {
    width: 1.4em;
  }

  &--positive {
    background-color: $color-success-surface;
    color: $color-success-object;
  }
  &--neutral {
    background-color: $color-surface-primary;
    color: $color-text-and-object-high-emphasis;
  }
  &--warning {
    background-color: $color-warning-surface;
    color: $color-warning-object;
  }
}
</style>
