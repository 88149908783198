<script lang="ts" setup>
const clicked = ref(false);
const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
});

const {
  public: { serverEnv, version },
} = useRuntimeConfig();
const hidden = computed(
  (): boolean => serverEnv === 'production' || clicked.value
);
</script>

<template>
  <ul
    v-if="!hidden"
    :class="[$style.bar, $style[`bar--${serverEnv}`]]"
    @click="clicked = true"
  >
    <li :class="[$style.bar__data, $style['bar__data--env']]">
      server: {{ serverEnv }}
    </li>
    <li :class="$style.bar__data">isMounted: {{ isMounted }}</li>
    <li v-if="version" :class="$style.bar__data">
      version: {{ version.slice(0, 14) }}
    </li>
  </ul>
</template>

<style lang="scss" module>
.bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  cursor: pointer;
  box-shadow: $shadow-4;
  display: flex;
  flex-wrap: wrap;
  gap: 2px 8px;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 4px 8px;
  font-size: 14px;

  &--local {
    background-color: rgba($color-label-blue, 0.8);
  }

  &--alpha {
    background-color: rgba($color-label-green, 0.8);
  }

  &--staging {
    padding: 8px;
    font-size: 18px;
    background-color: $color-label-red;
  }

  &--demo {
    background-color: rgba($color-label-yellow, 0.8);
  }

  &--production {
    display: none;
  }

  &__data {
    flex: 0 0 auto;
    list-style: none;
    color: $color-text-and-object-high-emphasis-inverse;
    text-align: center;

    &--env {
      font-weight: bold;
    }
  }
}
</style>
