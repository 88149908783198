<script setup lang="ts">
import AlertBox from '~theme/components/molecules/AlertBox.vue';
import type { AlertId, AlertItem, AlertType } from '~theme/types/alert';

const props = withDefaults(
  defineProps<{
    items: AlertItem[];
    enableAutoHide?: boolean;
  }>(),
  {
    enableAutoHide: false,
  }
);

const emits = defineEmits<{
  close: [value: AlertId];
}>();

const isAutoHide = (type: AlertType) => {
  const autoHiddenTypes: string[] = [ALERT_TYPES.NOTICE, ALERT_TYPES.SUCCESS];
  return props.enableAutoHide && autoHiddenTypes.includes(type);
};

const onClose = (value: AlertId) => {
  emits('close', value);
};
</script>

<template>
  <!-- guard position: relative -->
  <div>
    <!-- TODO: transition-groupがまともになったら共通コンポーネントに分ける -->
    <TransitionGroup :class="$style['alert-list']" name="fade-in" tag="ul">
      <li
        v-for="alert in items"
        :key="alert.id"
        :class="$style['alert-list__item']"
      >
        <AlertBox
          v-bind="alert"
          :auto-hide="isAutoHide(alert.type)"
          @close="onClose"
        />
      </li>
    </TransitionGroup>
  </div>
</template>

<style lang="scss" module>
.alert-list {
  position: relative;
  overflow: visible;

  &__item {
    transition: 300ms ease-out;
    transition-property: opacity transform;

    &:nth-child(n + 2) {
      margin-top: 2px;
    }
  }
}
</style>

<style lang="scss" scoped>
.fade-in {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-8%);
  }

  &-leave-active {
    position: absolute;
    width: 100%;
  }
}
</style>
