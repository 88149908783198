export const useStatusbox = (jsSrc: string, statusBoxUrls: string[]) => {
  useHead({
    script: [
      {
        src: jsSrc,
        type: 'module',
        defer: true,
      },
      {
        // ここで入れないとページ遷移時に正常に動かない
        innerHTML: `
          window.StatusBox = window.StatusBox || [];
          const statusBoxUrls = ${JSON.stringify(statusBoxUrls)};
          for (const url of statusBoxUrls) {
            window.StatusBox.push(url);
          }
        `,
      },
    ],
  });
};
