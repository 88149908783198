<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';
import NotificationPopup from 'theme/components/atoms/NotificationPopup.vue';
import FadeInTransition from 'theme/components/transitions/FadeInTransition.vue';

const { subscribe, unsubscribe } = useNotifications();

const isMessageNotificationVisible = ref(false);
const lastTimeoutId = ref<number | null>(null);
const show = async () => {
  isMessageNotificationVisible.value = true;
  if (lastTimeoutId.value != null) {
    clearTimeout(lastTimeoutId.value);
  }
  lastTimeoutId.value = window.setTimeout(() => {
    isMessageNotificationVisible.value = false;
    lastTimeoutId.value = null;
  }, 3000);
};

const onReceiveNewNotification = useDebounceFn(show, 100);

onMounted(() => {
  subscribe(onReceiveNewNotification);
});
onBeforeUnmount(() => {
  if (lastTimeoutId.value != null) {
    clearTimeout(lastTimeoutId.value);
  }
  unsubscribe(onReceiveNewNotification);
});
</script>

<template>
  <div :class="$style.container">
    <FadeInTransition>
      <NuxtLink
        v-if="isMessageNotificationVisible"
        :class="$style.container__item"
        :to="{ path: '/my/messages' }"
      >
        <NotificationPopup
          :class="$style.container__notification"
          variant="positive"
          message="たった今メッセージが届いたにゃ！"
        />
      </NuxtLink>
    </FadeInTransition>
  </div>
</template>

<style module lang="scss">
.container {
  width: 100%;
  max-width: 600px;
  &__notification {
    width: 100%;
  }
  &__item {
    display: block;
    width: 100%;
    padding: 16px;
  }
}
</style>
