<script lang="ts" setup>
import type { FragmentType } from '@/gql';
import { graphql } from '@/gql';
import { useWindowSize } from '@vueuse/core';
import AppMarkdown from 'theme/components/atoms/AppMarkdown.vue';
import ButtonFlat from 'theme/components/atoms/ButtonFlat.vue';
import ArrowLeft from '~theme/assets/icons/outline/arrow-left.svg?component';

const TermViewTermRevisionFragmentDef = graphql(`
  fragment TermViewTermRevision on TermRevision {
    id
    title
    body
  }
`);
const term = useComputedFragment(
  TermViewTermRevisionFragmentDef,
  () => props.term
);

const props = defineProps<{
  term: FragmentType<typeof TermViewTermRevisionFragmentDef>;
}>();

const { height } = useWindowSize();
const modalHeight = computed(() => {
  const h = height.value;
  return h ? `${h * 0.8}px` : '80vh';
});

const emit = defineEmits<{
  clickBackButton: [];
}>();

const onClick = () => {
  emit('clickBackButton');
};
</script>

<template>
  <div :class="$style.term">
    <h3 :class="$style.term__header">{{ term.title }}</h3>
    <div v-test="'termContent'" :class="$style.term__contents">
      <AppMarkdown :key="term.id" :body="term.body" />
    </div>
    <footer :class="[$style.term__footer, $style.footer]">
      <ButtonFlat :class="$style.term__button" variant="base" @click="onClick">
        <ArrowLeft :class="$style.button__arrow" />
        <p>戻る</p>
      </ButtonFlat>
    </footer>
  </div>
</template>

<style lang="scss" module>
.term {
  display: flex;
  flex-direction: column;
  margin: 8px;
  max-width: 420px;
  height: v-bind(modalHeight);

  &__header {
    flex: 0 0 auto;
    font-size: 20px;
    line-height: 36px;
    font-weight: bold;
    text-align: center;
  }

  &__contents {
    flex: 1 0 0px;
    border-radius: $border-radius-rounder;
    margin: 24px 8px 0 8px;
    max-width: 400px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  &__footer {
    flex: 0 0 auto;
    text-align: center;

    &:nth-child(n + 2) {
      margin-top: 16px;
    }
  }

  &__button {
    width: 100%;
    display: flex;
    gap: 11px;
  }
}

.button {
  &__arrow {
    height: 20px;
    width: 20px;
  }
}
</style>
