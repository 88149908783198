import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "18",
  height: "18",
  viewBox: "0 0 18 18",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M13.295 6.605L8.72699 12.605C8.53899 12.852 8.24799 12.998 7.93799 13H7.93099C7.62399 13 7.33399 12.858 7.14399 12.616L4.71199 9.509C4.37199 9.075 4.44799 8.446 4.88299 8.106C5.31799 7.765 5.94599 7.841 6.28699 8.277L7.91999 10.363L11.704 5.394C12.038 4.955 12.666 4.869 13.106 5.204C13.545 5.539 13.63 6.166 13.295 6.605",
      fill: "#030E1A",
      "fill-opacity": "0.2"
    }, null, -1)
  ])))
}
export default { render: render }