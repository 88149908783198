<script setup lang="ts">
import AlertCircle from 'theme/assets/icons/outline/alert-circle.svg?component';
import CheckmarkCircle from 'theme/assets/icons/outline/checkmark-circle.svg?component';
import ButtonClose from '~theme/components/atoms/ButtonClose.vue';
import { ALERT_TYPES } from '~theme/composables/useAlert';
import type { AlertId, AlertType } from '~theme/types/alert';

const AUTO_HIDE_DURATION: Millisecond = 10000;

const props = withDefaults(
  defineProps<{
    message?: string;
    type?: AlertType;
    id: AlertId;
    autoHide?: boolean;
    closable?: boolean;
  }>(),
  {
    message: '',
    type: ALERT_TYPES.NOTICE,
    autoHide: false,
    id: undefined,
    closable: true,
  }
);

const emit = defineEmits<{
  close: [value: number];
}>();

const timeoutId = ref<number>();
const style = useCssModule();
const rootClassnames = computed(() => [
  style.alert,
  style[`alert--${props.type}`],
]);

const close = () => emit('close', props.id);
const stopAutoHideIfNeeded = () =>
  timeoutId.value && window.clearTimeout(timeoutId.value);
const startAutoHideIfNeeded = () => {
  if (!props.autoHide) return;
  timeoutId.value = window.setTimeout(close, AUTO_HIDE_DURATION);
};

onMounted(startAutoHideIfNeeded);
onUnmounted(stopAutoHideIfNeeded);
</script>

<template>
  <!-- guard position: relative -->
  <div>
    <div
      :class="rootClassnames"
      @mouseover="stopAutoHideIfNeeded"
      @mouseout="startAutoHideIfNeeded"
    >
      <AlertCircle
        v-if="props.type === ALERT_TYPES.ERROR"
        :class="$style.alert__icon"
      />
      <CheckmarkCircle
        v-else-if="props.type === ALERT_TYPES.SUCCESS"
        :class="$style.alert__icon"
      />

      <p :class="$style.alert__message">{{ message }}</p>
      <button-close
        v-if="props.closable"
        :class="$style.alert__button"
        @click="close"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.alert {
  $root: &;

  position: relative;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  align-items: center;
  padding: 18px 24px;
  gap: 24px;
  background-color: $color-surface-primary;
  border-radius: $border-radius-rounder;

  &__icon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    fill: currentColor;

    .alert--success & {
      color: $color-success-accent;
    }

    .alert--error & {
      color: $color-caution-accent;
    }
  }

  &--success {
    background-color: $color-success-surface;
  }

  &--error {
    background-color: $color-caution-surface;
  }

  &--warning {
    background-color: $color-warning-surface;
  }

  &__message {
    flex: 1 1 auto;
    color: $color-text-and-object-high-emphasis;
    font-size: 14px;
    font-weight: bold;

    .alert--success & {
      color: $color-success-object;
    }

    .alert--error & {
      color: $color-caution-object;
    }

    .alert--warning & {
      color: $color-warning-object;
    }
  }

  &__button {
    box-sizing: border-box;
    flex: 0 0 24px;
    padding: 5px;
    color: $color-text-and-object-high-emphasis;
    font-size: 18px;

    .alert--success & {
      color: $color-success-object;
    }

    .alert--error & {
      color: $color-caution-object;
    }

    .alert--warning & {
      color: $color-warning-object;
    }
  }
}
</style>
