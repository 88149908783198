<script setup lang="ts">
import NotificationBanners from '@/components/organisms/NotificationBanners.vue';
import StickyFooter from 'theme/components/functional/StickyFooter.vue';
import AlertBoxList from 'theme/components/organisms/AlertBoxList/AlertBoxList.vue';
import DevEnvironmentBar from 'theme/components/organisms/DevEnvironmentBar/DevEnvironmentBar.vue';
import { useStatusbox } from 'theme/composables/useStatusbox';
import AgreementUpdateConfirmation from '~/components/organisms/AgreementUpdateConfirmation/AgreementUpdateConfirmation.vue';

defineProps<{
  isAuthenticated: boolean;
}>();

const runtimeConfig = useRuntimeConfig();

useStatusbox(runtimeConfig.public.statusboxJs, [
  runtimeConfig.public.statusboxJson,
]);

const alert = useAlert();
const alertItems = computed(() => alert.data.value.items);

const isMounted = ref(false);
onMounted(() => {
  isMounted.value = true;
});
</script>

<template>
  <div :class="$style.base" :data-mounted="isMounted">
    <ClientOnly>
      <div id="statusbox-notifications"></div>
    </ClientOnly>
    <DevEnvironmentBar />
    <ClientOnly>
      <AgreementUpdateConfirmation v-if="isAuthenticated" />
    </ClientOnly>
    <AlertBoxList
      :items="alertItems"
      :enable-auto-hide="true"
      :class="$style.base__alert"
      @close="(value) => value != null && alert.delete(value)"
    />
    <slot />
    <StickyFooter>
      <NotificationBanners
        v-if="isAuthenticated"
        :class="$style.base__messageNotifications"
      />
    </StickyFooter>
  </div>
</template>

<style lang="scss" module>
.base {
  isolation: isolate;

  &__modal {
    z-index: 2;
  }

  &__alert {
    position: fixed;
    top: 20px;
    right: 20px;
    left: 20px;
    z-index: 3;
  }

  &__messageNotificationsWrapper {
    width: 100%;
    padding: 0 auto;
  }
  &__messageNotifications {
    margin: 0 auto;
  }
}
</style>
