import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "25",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "currentColor",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M7.5002 11.9993C7.5002 11.4473 7.9482 10.9993 8.5002 10.9993C9.0522 10.9993 9.5002 11.4473 9.5002 11.9993C9.5002 12.5513 9.0522 12.9993 8.5002 12.9993C7.9482 12.9993 7.5002 12.5513 7.5002 11.9993ZM12.5002 10.9993C11.9482 10.9993 11.5002 11.4473 11.5002 11.9993C11.5002 12.5513 11.9482 12.9993 12.5002 12.9993C13.0522 12.9993 13.5002 12.5513 13.5002 11.9993C13.5002 11.4473 13.0522 10.9993 12.5002 10.9993ZM16.5002 10.9993C15.9482 10.9993 15.5002 11.4473 15.5002 11.9993C15.5002 12.5513 15.9482 12.9993 16.5002 12.9993C17.0522 12.9993 17.5002 12.5513 17.5002 11.9993C17.5002 11.4473 17.0522 10.9993 16.5002 10.9993ZM20.3986 13.2942C19.8916 16.5482 17.2686 19.2472 14.0196 19.8562C12.4506 20.1522 10.8526 19.9832 9.4026 19.3692C8.9916 19.1952 8.5666 19.1072 8.1496 19.1072C7.9596 19.1072 7.7716 19.1252 7.5866 19.1622L4.7746 19.7242L5.3376 16.9072C5.4556 16.3222 5.3836 15.6962 5.1306 15.0972C4.5166 13.6472 4.3486 12.0502 4.6436 10.4802C5.2526 7.23117 7.9506 4.60817 11.2056 4.10117C13.7956 3.69817 16.3286 4.51417 18.1566 6.34217C19.9856 8.17117 20.8026 10.7052 20.3986 13.2942ZM19.5716 4.92817C17.2866 2.64417 14.1266 1.62517 10.8976 2.12417C6.8206 2.76017 3.4406 6.04417 2.6776 10.1112C2.3096 12.0692 2.5216 14.0632 3.2886 15.8762C3.3856 16.1072 3.4156 16.3222 3.3776 16.5152L2.5196 20.8032C2.4536 21.1312 2.5566 21.4702 2.7936 21.7062C2.9826 21.8962 3.2376 21.9992 3.5006 21.9992C3.5656 21.9992 3.6306 21.9932 3.6966 21.9802L7.9796 21.1232C8.2256 21.0762 8.4636 21.1452 8.6226 21.2112C10.4376 21.9782 12.4316 22.1892 14.3876 21.8222C18.4556 21.0592 21.7396 17.6792 22.3756 13.6022C22.8776 10.3752 21.8566 7.21317 19.5716 4.92817Z"
    }, null, -1)
  ])))
}
export default { render: render }