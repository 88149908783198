<script setup lang="ts" generic="Value, Content, M extends boolean | Value[]">
import checkmarkSquare from '~theme/assets/icons/fill/checkmark-square.svg?component';
import checkboxOff from '~theme/assets/icons/outline/checkbox-off.svg?component';

export type CheckboxBlockOption<Value, Content> = {
  value: Value;
  content: Content;
};

const props = withDefaults(
  defineProps<{
    modelValue: M;
    option: CheckboxBlockOption<Value, Content>;
    name?: string;
    disabled?: boolean;
  }>(),
  {
    name: undefined,
    disabled: false,
  }
);

const emit = defineEmits<{
  'update:modelValue': [value: M];
}>();

const checked = computed((): boolean =>
  Array.isArray(props.modelValue)
    ? props.modelValue.some(
        (modelValue) =>
          JSON.stringify(modelValue) === JSON.stringify(props.option.value)
      )
    : props.modelValue
);

const handleChange = () => {
  if (!Array.isArray(props.modelValue)) {
    emit('update:modelValue', !props.modelValue as M);
    return;
  }

  const nextValue = checked.value
    ? props.modelValue.filter(
        (modelValue) =>
          JSON.stringify(modelValue) !== JSON.stringify(props.option.value)
      )
    : [...props.modelValue, props.option.value];
  emit('update:modelValue', nextValue as M);
};
</script>

<template>
  <label
    :class="[
      $style.checkboxBlock,
      {
        [$style['checkboxBlock--checked']]: checked,
        [$style['checkboxBlock--disabled']]: disabled,
      },
    ]"
  >
    <input
      type="checkbox"
      :class="[
        $style.checkboxBlock__checkbox,
        {
          [$style['checkboxBlock__checkbox--checked']]: checked,
        },
      ]"
      :value="option.value"
      :checked="checked"
      :disabled="disabled"
      @change="handleChange"
    />
    <checkmarkSquare v-if="checked" :class="$style.checkboxBlock__checkmark" />
    <checkboxOff v-else :class="$style.checkboxBlock__checkboxOff" />
    <div
      :class="[
        $style.checkboxBlock__content,
        {
          [$style['checkboxBlock__content--checked']]: checked,
        },
      ]"
    >
      <slot :content="option.content" :checked="checked" :disabled="disabled">
        {{ option.content }}
      </slot>
    </div>
  </label>
</template>

<style lang="scss" module>
.checkboxBlock {
  $root: &;
  display: flex;
  align-items: center;
  border: 1px solid $color-border-low-emphasis;
  border-radius: $border-radius-rounder;
  padding: 12px;
  background-color: $color-surface-primary;
  cursor: pointer;
  transition:
    background-color $transition-interaction,
    border-color $transition-interaction,
    color $transition-interaction;

  @include is-pointer-device {
    &:hover {
      border-color: $color-border-medium-emphasis;
    }

    &:hover#{&}--checked {
      border-color: $color-border-accent-primary;
    }
  }

  &--checked {
    border-color: $color-border-accent-primary;
    background-color: $color-surface-accent-primary;
    color: $color-text-and-object-high-emphasis-inverse;
  }

  &--disabled {
    border-color: $color-border-low-emphasis;
    background-color: $color-surface-tertiary;
    color: $color-text-and-object-disable;

    @include is-pointer-device {
      &:hover {
        border-color: $color-border-low-emphasis;
      }

      &:hover#{&}--checked {
        border-color: $color-border-low-emphasis;
      }
    }
  }

  &__input {
    flex: 0 0 auto;
  }

  &__content {
    flex: 1 1 auto;

    &:nth-child(n + 2) {
      margin-left: 8px;
    }

    &--checked {
      font-weight: bold;
      color: $color-text-and-object-high-emphasis-inverse;
    }
  }

  &__checkbox {
    appearance: none;
  }

  &__checkboxOff {
    border-radius: 4px;
    border: 1px solid $color-border-medium-emphasis;
    height: 18px;
    width: 18px;
  }

  &__checkmark {
    height: 18px;
    width: 18px;
  }
}
</style>
