<script lang="ts" setup>
import AgreementView from '@/components/organisms/AgreementView/AgreementView.vue';
import AgreementViewWithCheckbox from '@/components/organisms/AgreementView/AgreementViewWithCheckbox.vue';
import { graphql } from '@/gql';
import { TermType } from '~/gql/graphql';

const query = graphql(`
  query AgreementUpdateConfirmation {
    viewer2 {
      ... on ViewerQueryErrorPayload {
        error
      }
      ... on ViewerQuerySuccessPayload {
        result {
          id
          requiredAgreementTerms {
            type
            ...AgreementViewTermRevision
            ...AgreementViewWithCheckboxTermRevision
            ...TermViewTermRevision
          }
        }
      }
    }
  }
`);

const agreeTermsRequireAgreementQuery = graphql(`
  mutation AgreementUpdateConfirmationAgreeTerms($termRevisionIDs: [ID!]!) {
    agreeTerms(termRevisionIDs: $termRevisionIDs) {
      requiredAgreementTerms {
        ...AgreementViewTermRevision
      }
    }
  }
`);

const { TermAgreementModal, openTermAgreementModal, closeTermAgreementModal } =
  useModalNamed('termAgreement');

const {
  TermAgreementWithCheckboxModal,
  openTermAgreementWithCheckboxModal,
  closeTermAgreementWithCheckboxModal,
} = useModalNamed('termAgreementWithCheckbox');

const { data } = await useAsyncAuthedQuery(query, undefined, {
  apollo: {
    fetchPolicy: 'network-only',
  },
});
const terms = computed(() => {
  return data.value?.viewer2?.__typename === 'ViewerQuerySuccessPayload'
    ? data.value.viewer2.result.requiredAgreementTerms
    : [];
});

const mustReadTerms = computed(() =>
  terms.value.filter((term) => term.type === TermType.SecrecyOfCommunications)
);

const otherTerms = computed(() =>
  terms.value.filter((term) => term.type !== TermType.SecrecyOfCommunications)
);

onBeforeMount(() => {
  if (mustReadTerms.value.length > 0) {
    openTermAgreementModal();
  } else if (otherTerms.value.length > 0) {
    openTermAgreementWithCheckboxModal();
  }
});

const { mutate: updateConfirmationAgreeTerms } = useMutation(
  agreeTermsRequireAgreementQuery
);
const agree = (termRevisionIDs: string[]) => {
  updateConfirmationAgreeTerms({ termRevisionIDs });
  closeTermAgreementModal();
  if (otherTerms.value.length > 0) {
    openTermAgreementWithCheckboxModal();
  }
};

const agreeWithCheckbox = (termRevisionIDs: string[]) => {
  updateConfirmationAgreeTerms({ termRevisionIDs });
  closeTermAgreementWithCheckboxModal();
};
</script>

<template>
  <div>
    <TermAgreementModal disable-backdrop>
      <AgreementView v-test="'agreement'" :terms="mustReadTerms" @agree="agree">
        <template #submit>同意してサービスを利用する</template>
      </AgreementView>
    </TermAgreementModal>
    <TermAgreementWithCheckboxModal disable-backdrop>
      <AgreementViewWithCheckbox
        v-test="'agreementWithCheckbox'"
        :terms="otherTerms"
        @agree="agreeWithCheckbox"
      />
    </TermAgreementWithCheckboxModal>
  </div>
</template>
